import React, { useState } from 'react';
import axios from 'axios';

const apiKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY_TRANSLATE || '';

interface GoogleSearchResult {
    title: string;
    link: string;
    snippet: string;  // Assuming all these properties exist and are strings.
  }



interface NewsTranslations {
    [key: string]: string; // Key will dynamically adjust to proper language codes
}

interface EnrichedNewsItem {
    date: string;
    talent_index: string;
    profile_picture: string;
    talent_job: string;
    news: NewsTranslations & { en: string };  // Ensures 'en' is always present along with any number of translated versions
    src: string;
}

interface EnrichedAPIResponse {
    [date: string]: EnrichedNewsItem;
}

 




const UpdateNewsComponent = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const apiKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY_TRANSLATE;  // Your API key for Google Translate


   
    const translateText = async (text: string, targetLanguage: string) => {
        const url = `https://translation.googleapis.com/language/translate/v2`;
        try {
            const response = await axios.post(url, {
                q: text,
                target: targetLanguage,
                format: 'text'
            }, {
                params: { key: apiKey }
            });
            return response.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error while translating:', error);
            return ''; // Return empty string if translation fails
        }
    };

    const enrichAPIResponseWithTranslations = async (apiResponse: any) => {
        const languageMap = {
            'ar': 'ar',
            'he': 'he',
            'zh-CN': 'cn',
            'ja': 'jp',
            'ko': 'kr',
            'fr': 'fr',
            'it': 'it',
            'es': 'sp',
            'pt': 'pt',
            'de': 'de',
            'nl': 'nl',
            'ru': 'ru'
        };

        for (const item of apiResponse) {
            const newsDetails = item[Object.keys(item)[0]].news; // Accessing news using dynamic key
            if (newsDetails.en !== '') {
                await Promise.all(Object.keys(languageMap).map(async (standardLang) => {
                    const customLang = languageMap[standardLang as keyof typeof languageMap];
                    if (!newsDetails[customLang]) {
                        newsDetails[customLang] = await translateText(newsDetails.en, standardLang);
                        console.log(`Translated [${customLang}]: ${newsDetails[customLang]}`);
                    }
                }));
            }
        }

        console.log('Translation complete:', apiResponse);
        return apiResponse;
    };


    const sendItemByItem = async (parsedResponse: any, talent: any) => {
        // Loop through each item in the parsedResponse
        for (let i = 0; i < parsedResponse.length; i++) {
          // Extract the item (which is an object with one key)
          const item = parsedResponse[i];
      
          // Extract the key from the object (since each item has one key-value pair)
          const itemKey = Object.keys(item)[0];
          const itemValue = item[itemKey];
      
          // Construct the payload to send
          const apiPayload = {
            talentREF: talent,
            APIResponse: {
              [itemKey]: itemValue
            }
          };
      
          console.log('Sending item-:', apiPayload);
      
          try {
            // Send the individual item using axios
            await axios.post(`${linkUrl}/wp-json/custom/v1/update-news`, apiPayload);
            console.log(`Item ${i + 1} sent successfully`);
          } catch (error) {
            console.error(`Error sending item ${i + 1}:`, error);
          }
        }
      };


    const walletAddress = localStorage.getItem('walletAddress');

    // Only display if the walletAddress is the one we're looking for
    if (walletAddress !== '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d') {
        return null;
    }

    const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`)
        ? `https://${stagingKey}.peaxel.me`
        : 'https://peaxel.me';

    const handleUpdateNews = async () => {
        setLoading(true);
        setError('');


        try {
            // Step 1: Fetch talents from WordPress API
            const talentsResponse = await axios.get(`${linkUrl}/wp-json/custom/v1/get-talents`);
            const talents = talentsResponse.data;

            console.log('talentsNews', talents);

            // Step 2: Iterate through talents and fetch recent news for each talent
            for (const talent of talents) {
                const talentFocusURL = `"${talent.first_name.replace(/\s+/g, '%20')}%20${talent.last_name.replace(/\s+/g, '%20')}"%20${talent.job.replace(/\s+/g, '%20')}`;
                const talentFocus = `${talent.first_name} ${talent.last_name}, ${talent.job} from ${talent.main_nationality}`;
                console.log('talentFocus', talentFocus);
                console.log('talentFocusURL', talentFocusURL);

                // Step 3: Fetch news using Google Custom Search API for the last 7 days
                const currentDate = new Date().toISOString().split('T')[0];  // Format: YYYY-MM-DD
                const lastWeekDate = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // 7 days ago
                
                const googleSearchUrl = `https://customsearch.googleapis.com/customsearch/v1?q=${talentFocusURL}+after:${lastWeekDate}&key=${process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_API_KEY}&cx=${process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_ENGINE_ID}&sort=date`;
                console.log('googleSearchUrl', googleSearchUrl);
                
                const googleResponse = await axios.get(googleSearchUrl);
                

                const googleResults = googleResponse.data.items || [];

                const formattedResults = (googleResults as GoogleSearchResult[]).map(result =>
                    `${result.title} - News from: ${result.link} on ${result.snippet}.`
                  ).join(" ");


                console.log('Formated Search Result:', formattedResults);

                // Step 4: Call OpenAI to process the fetched news
                const openAIResponse = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-4',
                        messages: [
                            { role: 'system', content: 'You are a helpful assistant programmed to provide factual information in requested format without any additional comments, information, explanations, chats even if failure - ONLY Expected JSON or [].' },
                            {
                                role: 'user',
                                content: `Provided data about ${talentFocus}: ${formattedResults}. Based on the provided data:
                                Identify and provide consolidated recent news/information about ${talentFocus}. No promotion, just facts and news. 200 characters max in English per news. Always use CONDITIONAL if info is not 100% validated. IF NO RELEVANT INFO, NOTHING TO PROPOSE. One main news per day max. The most important news or achievement.
                                For each event, define date (YYYY-MM-DD) of the news. Put a related link to the source. Create an index for each news. TALENT_INDEX-DATE-NUMBER where number is random between 1 and 100, e.g., ROMUALD-2024-01-01-37.
                                A JSON FILE is returned per news if there is news, else nothing is returned.
                                Structure:
                                - ${talent.talent_uppercase}-DATE-NUMBER {
                                  "date": "YYYY-MM-DD",
                                  "talent_index": "${talent.talent_uppercase}",
                                  "profile_picture": "${talent.profile_picture}",
                                  "talent_job": "${talent.job}",
                                  "news": {
                                    "en": "news in english",
                                    "ar": "",
                                    "he": "",
                                    "cn": "",
                                    "jp": "",
                                    "kr": "",
                                    "fr": "",
                                    "it": "",
                                    "sp": "",
                                    "pt": "",
                                    "de": "",
                                    "nl": "",
                                    "ru": ""
                                  },
                                  "src": ""
                                }`
                            },
                        ],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

               
        let APIResponse = openAIResponse.data.choices[0].message.content;
        const cleanJSONString = APIResponse.replace(/^[^{\[]*/, '').replace(/[^}\]]*$/, '');
        console.log('APIResponseInit', cleanJSONString);
        try {
        APIResponse = JSON.parse(cleanJSONString.replace(/\s+(?=[\{\}\[\],])/g, '').replace(/(?<=[\{\}\[\],])\s+/g, ''));
        console.log('APIResponseafter', APIResponse);

        


        if (APIResponse.length > 0) {
            const transformedResponse = await enrichAPIResponseWithTranslations(APIResponse);
            console.log('Transformed Response:', transformedResponse);

            const jsonResponse = JSON.stringify(transformedResponse);

            console.log('jsonResponseFinal', jsonResponse);



            const parsedResponse = JSON.parse(jsonResponse);

            sendItemByItem(parsedResponse, talent.talent_uppercase);


    
                    } else {
                        console.log('No news data to update.');
                    }

        } catch (parseError) {
                    console.error('Failed to parse JSON:', parseError);
                }
               
        
            }

            setLoading(false);
            alert('News updated successfully!');
        } catch (err) {
            setLoading(false);
            setError('Failed to update news: ');
            console.error(err);
        }
    };

    return (
        <div>
            <button onClick={handleUpdateNews} disabled={loading}>
                {loading ? 'Updating...' : 'Update News'}
            </button>
            {error && <p>{error}</p>}
        </div>
    );
};

export default UpdateNewsComponent;
